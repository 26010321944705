/*
ANNEE LUMIERE
**/

.selectStyle{
    color: $blackText !important
}
.radioInput{ 
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left : .2rem;
    width: 55px;
    height: 25px;
    white-space: nowrap;
    input{ 
        margin-right: .5rem !important;
        min-width: 15px;
    }

}

// .radioList{
//     padding-left: 0 !important;
// }


.choiceList{ 
    padding-left: .5rem;
    font-size: 1.2rem;
    font-weight: 500px;
}

.radioContainer{ 
    margin-top: .5rem;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;


}


#form-sos{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;

    @media screen and (max-width: $bp-small) {
        margin-top: 0;
      }


    .form-container {
        display: flex;
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        box-sizing: border-box;

        .form-row{ 
            width: 100%;
            display: flex;
            box-sizing: border-box;

            .marginUp {

                margin-top: 1rem;
                padding-left: 0 !important
                ;
            }
            .col100{
                width:100%;

                .columnWrap{
                    background-color: transparent;
                    background-image: linear-gradient(180deg, #F7F7FA 0%, #EDEDF1 100%);

                    .widgetWrap {
                        padding: 10px;
                        display: flex;
                        position: relative;
                        width: 100%;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        box-sizing: border-box;
                    }
                }
            }

            .col70{
                width:70%;

                .columnWrap{
                    background-color: transparent;
                    background-image: linear-gradient(180deg, #F7F7FA 0%, #EDEDF1 100%);

                    .widgetWrap {
                        padding: 10px;
                        display: flex;
                        position: relative;
                        width: 100%;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        box-sizing: border-box;
                    }
                }
            }

            .col80{
                width:80%;

                .columnWrap{
                    background-color: transparent;
                    background-image: linear-gradient(180deg, #F7F7FA 0%, #EDEDF1 100%);

                    .widgetWrap {
                        padding: 10px;
                        display: flex;
                        position: relative;
                        width: 100%;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        box-sizing: border-box;
                    }
                }
            }
        }
    
    }
}

#divider{
    
    @media screen and (max-width: $bp-medium) {
        display: none;
      }

}

#form-titleContainer{
    text-align: center;
    margin-bottom: 20px;
    width: 100%;

    .widgetContainer{
        padding: 040ox 0px 18px 0px;

        h1{
            color: $primaryColor;
            font-weight: 800;
            line-height: 44px;
            font-family: $font_stack;
            text-transform: capitalize;
        }

    }

}

#form-formContainer{
    margin-bottom: 20px;
    width: 100%;
    .widgetContainer{

        box-sizing: border-box;
        margin: 0px 0px 0px 0px;
        padding: 0px 30px 0px 30px;

        form{
            box-sizing: border-box;

            .formWrap {
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;

                margin-left: calc( -60px/2 );
                margin-right: calc( -60px/2 );
                margin-bottom: -31px;
            }
        }

    }

}

.form-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc( -6px/2 );
    margin-right: calc( -60px/2 );
    margin-bottom: -31px;
    box-sizing: border-box;

    @media screen and (max-width: $bp-medium) {
        margin-left: 0;
      }
}


.formSection{ 
    padding-bottom: 0px;
    color: $primaryColor;
    font-family: $font_stack;
    font-weight: 400;
    width: 100%;
    display: inline-block;

    padding-right: calc( 60px/2 );
    padding-left: calc( 60px/2 );
    margin-bottom: 31px;

    @media screen and (max-width: $bp-small) {
        text-align: center !important;
      }
    @media screen and (max-width: 480px) {
        padding-left: 0;
      }

    

    b{
        font-size: 1.2rem;
        font-weight: bolder;
        
    }
}

#codePostal, #commune{
    width: 25%;
}

.row100{
    width:100% !important;
}

.flexCol{
    flex-direction: column !important;
}

.containerBtn{
    align-items: baseline !important;
}


.radioBtn{
    display: flex;
    flex-direction: row !important;
    box-sizing: border-box;

    flex-basis: 100%;
    max-width: 100%;

    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    position: relative;
    min-height: 1px;

    .radioContainer{
        padding-right: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: $bp-medium) {

            font-size: 15px;
    
          }
        

        input {
          
            display: inline;
            padding: 0;
            overflow: visible;
            font-family: inherit;
            font-size: 1rem;
            line-height: 1.5;
            margin: 0;
            margin-top: -.75rem;
            margin-right: .5rem;
        }
        label { 
            font-weight: 450;
            display: inline;
            line-height: 1;
            vertical-align: middle;
           
        }

    }
}



.form-input{

    @media screen and (max-width: $bp-medium) {
        width: 100% !important;
        padding-left: 0 !important;
        font-size: 15px;

      }

    color: $placeholder;

    padding-right: calc( 60px/2 );
    padding-left: calc( 60px/2 );

    margin-bottom: 31px;

    width: 50%;
    flex-wrap: wrap;
    align-items: center;

    position: relative;
    min-height: 1px;
    display: flex;
    box-sizing: border-box;

    label {
        font-family: $font_stack;
        padding-bottom: 5px;
        font-weight: 600;
        font-size: 16px;
        color: $primaryColor;


    }

    .obligatoire{
        &:after{
            content: "*";
            color: red;
            padding-left: .2rem;
            cursor: pointer;
            
        }

        @media screen and (max-width: $bp-small) {
           font-size: 15px;
          }
    }


    

    input {
        background-color: #ffffff;
        border-color: $primaryColor;
        border-radius: 0px 0px 0px 0px;

        flex-basis: 100%;
        max-width: 100%;

        border-radius: $radius;

        width: 100%;
        border: 1px solid #69727d;
        padding: .1rem 1rem;
        transition: all .3s;

        line-height: 1.4;
        font-size: 15px;
        min-height: 40px;

        overflow: visible;
        margin: 0;


    }

    .file-div{
        @media screen and (max-width: $bp-small) {
            width: 100% !important;
           }


        display: inline-block;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &:hover{
            background-color: #69727d;
        }


        .btn-upload{
            background-color: #fff;
            border: 1px solid $primaryColor;
            color: $primaryColor;
            padding: 10px 25px;
            border-radius: $radius;
            font-size: .9rem;
            font-weight: 500;
            line-height: .9rem;
            letter-spacing: .5px;

            @media screen and (max-width: $bp-small) {
                width: 100%;
               }

            cursor: pointer;

            &:hover{
                background-color: #69727d;
            }
        
        }

            .file{
                cursor: pointer;

                
                @media screen and (max-width: $bp-small) {
                width: 100% !important;
               }

                &:hover{
                    background-color: #69727d;
                }

                background-color: transparent !important;
                border: none !important;
                flex-basis: 100%;
                max-width: 100%;
                 
    
                left: 0;
                top: 0;
                opacity: 0;
                position: absolute;
    
                
                overflow: visible;
                margin: 0;
                font-size: 5rem;
                line-height: 1.5;
                padding-left: 0;
            }
    


        



    }

    select{ 

        background-color: #fff;
        border: 1px solid $primaryColor;
        color: $primaryColor;
        padding: 12px 15px;
        border-radius: $radius;
        font-size: .9rem;
        font-weight: 500;
        line-height: .9rem;
        letter-spacing: .5px;
        width: 100%;
    }

    textarea{
        width: 100%;
        min-height: 100px;
    }



}


.field-group{
    
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .field-option{
        flex-basis: 100%;
        display: block;
        align-items: center;

        
        .field-checkbox{
           display: inline;
           overflow: visible;
           width: 3% !important;
           margin: 0;
           min-height: 0;

           @media screen and (max-width: 480px) {
                width: 11% !important;
          }



        } 

        .field-notes{
            padding-left: 2px;
            width: 100%;
            display: inline;

            @media screen and (max-width: $bp-medium) {
                
                  font-size: 14px;
                  font-weight: 500;
                
              }

        }
    }

}


.fileContainer{
    width: 85% !important;
    
    @media screen and (max-width: 480px) {
        width: 100% !important;
    
      }
  
}
.selectContainer{
    width: 75% !important;

    @media screen and (max-width: 480px) {
        width: 100% !important;
      }
}
.checkboxContainer{
    width: 66% !important;
    @media screen and (max-width: $bp-small) {
        margin-bottom: 1rem !important;
        width: 100% !important;
      }
}
.checkboxContainer2{
    width: 96% !important;
    margin-bottom: 2px;

    @media screen and (max-width: $bp-small) {
        margin-bottom: 1rem !important;
        width: 100% !important;
      }
}



#autorite{
    color:$primaryColor;
    font-family: $font_stack;
    font-weight: $hard-ftWeight;
}

.dividerForm>hr{
    height:2px;
    border-width:0;
    color:white;
    background-color:white; 
    margin-left:-9px; 
    width:102%; 
    margin-top:30px; margin-bottom:20px;

    padding-right: calc( 60px/2 );
    padding-left: calc( 60px/2 );
    margin-bottom: 31px;
}

#rgpd{
    color: $redAlertSpecial;
    text-decoration: none;

    &:hover{ 
        color: $primaryColor;
        text-decoration:solid;
    }
}

.btn-submit{
    padding: auto;
    margin-top: 1rem;

    margin-bottom: 31px;
    width: 100%;
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 1px;

    button{
        background-color: $primaryColor;
        color: $white;
        flex-basis: 100%;
        border-radius: $radius;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        cursor: pointer;
        min-height: 2.5rem;
        max-height: 3rem;
        
        font-weight: 600;
        font-size: .9rem;

        &:hover{
            background-color: $blackText;
        }

    }
}


.sinistreContainer{
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $bp-medium) {
        flex-direction: column;
      }
    
}

#dateSinistre{
    width: 100% !important;
}

.companyContainer{
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $bp-medium) {
        flex-direction: column;
      }
    
}

.tailleMax{
    font-size: 12px;

    @media screen and (max-width: $bp-medium) {
        
          font-size: 10px;
        
      }
}

.redAlertSpecial{
    color:$redAlertSpecial;
    text-decoration: none;
}

.cgv{
    color: black;
    font-size: 11px;
    font-weight: 400;
    font-family: $font_stack;
    margin-bottom: 20px;
    width: 100%;

    .widgetContainer{
        margin: 0px 30px 0px 30px;
        transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
        box-sizing: border-box;
        p{
            padding-left: .5rem;
            margin-top: 0;
            margin-bottom: 0.9rem;

        }
    }

}


.formWrapperNoSinistre{
    display: flex;
    flex-direction: row;
    margin-top: -2rem;
    margin-bottom: 2rem;


    
    @media screen and (max-width: $bp-medium) {
        
        flex-direction: column;
        margin-top: 1rem;
      
    }

    }